.dropzoneStyle {
  width: 100%;
  height: 50px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: solid;
  margin-top: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
}
.MuiTableCell-root {
  padding: 6px;
  line-height: 1.13;
}
.buttonWrapper input[type=file] {
  font-size: 100px;
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  height: 45px;
}

.customHeight {
  height: 700px!important;
}

.tableBlock table th,
.tableBlock table td {
  padding: 5px 10px;
}

.filterBlock {
  margin-bottom: 15px;
}
.filterBlockInner {
  padding: 15px;
}
