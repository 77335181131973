.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 501;
  top: 0;
  left: 0;
  background-color: rgba(150, 150, 150, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}